import React, { FC, memo, useState } from 'react';
import { Highlight } from 'prism-react-renderer';

// import {
//   generateRandomString,
//   programmingLanguages,
// } from '@utils/app/codeblock';
import { Button, Flex, Typography } from '@duxton-web/ui';
import {
  CheckmarkUniversal,
  CopyOutlined,
  DownloadUniversal,
} from '@duxton-web/icons';

export const CodeBlock = memo(({ language, value }) => {
  const [isCopied, setIsCopied] = useState(false);

  // const copyToClipboard = () => {
  //   if (!navigator.clipboard || !navigator.clipboard.writeText) {
  //     return;
  //   }

  //   navigator.clipboard.writeText(value).then(() => {
  //     setIsCopied(true);

  //     setTimeout(() => {
  //       setIsCopied(false);
  //     }, 2000);
  //   });
  // };
  // const downloadAsFile = () => {
  //   const fileExtension = programmingLanguages[language] || '.file';
  //   const suggestedFileName = `file-${generateRandomString(
  //     3,
  //     true,
  //   )}${fileExtension}`;
  //   const fileName = window.prompt('Enter file name' || '', suggestedFileName);

  //   if (!fileName) {
  //     // user pressed cancel on prompt
  //     return;
  //   }

  //   const blob = new Blob([value], { type: 'text/plain' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.download = fileName;
  //   link.href = url;
  //   link.style.display = 'none';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };
  return (
    <div
      style={{
        color: 'white',
        backgroundColor: 'black',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Flex
        align="center"
        justify="space-between"
        style={{
          padding: '4px 16px',
        }}
      >
        <Typography.Text style={{ color: 'white' }}>{language}</Typography.Text>
        <Flex align="center" gap="16px">
          {/* <Button
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
              gap: '5px',
            }}
            onClick={copyToClipboard}
          >
            {isCopied ? <CheckmarkUniversal /> : <CopyOutlined />}
            {isCopied ? 'Copied!' : 'Copy code'}
          </Button> */}
          {/* <Button
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
              gap: '5px',
            }}
            onClick={downloadAsFile}
          >
            <DownloadUniversal />
          </Button> */}
        </Flex>
      </Flex>

      <Highlight code={value} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            style={{ ...style, margin: 0, padding: '8px', textWrap: 'wrap' }}
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  );
});
CodeBlock.displayName = 'CodeBlock';
