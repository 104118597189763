import { createContext } from 'react';
// import { ActionType } from '../../hooks/useCreateReducer';
// import {
//   ChatModeEnum,
//   Conversation,
//   Message,
//   SelectedChatOptionType,
// } from 'src/types/chat';
// import { KeyValuePair } from 'src/types/data';
// import { FolderType } from 'src/types/folder';
// import { HomeInitialState } from './home.state';
// export interface HomeContextProps {
//   state: HomeInitialState;
//   dispatch: Dispatch<ActionType<HomeInitialState>>;
//   handleNewConversation: (option?: {
//     conversation?: Partial<Conversation>;
//     initialMessage?: Message;
//   }) => void;
//   handleCreateFolder: (name: string, type: FolderType) => void;
//   handleDeleteFolder: (folderId: number) => void;
//   handleUpdateFolder: (folderId: number, name: string) => void;
//   handleSelectConversation: (conversation: Conversation) => void;
//   handleUpdateConversation: (
//     conversation: Conversation,
//     data: KeyValuePair,
//   ) => void;
//   handleSend: (
//     message: Message,
//     option?: {
//       deleteCount?: number | undefined;
//       conversation?: Conversation | undefined;
//     },
//   ) => Promise<void>;
//   setChatMode: (mode: ChatModeEnum, convo: Conversation) => void;
//   setChatOptions: (
//     options: SelectedChatOptionType,
//     convo: Conversation,
//   ) => void;
// }
const HomeContext = createContext(undefined);
export default HomeContext;
