import React, { memo, useContext } from 'react';
import HomeContext from '@pages/context/HomeContext';
import { Flex } from '@duxton-web/ui';
import AssistantChatMessage from './AssistantChatMessage';
import UserChatMessage from './UserChatMessage';


export const ChatMessage = memo(({ message, messageIndex }) => {
  const {
    state: { conversations, messageIsStreaming },
  } = useContext(HomeContext);

  const renderChat = () => {
    if (message.role === 'assistant') {
      return (
        <AssistantChatMessage
          message={message}
          messageIsStreaming={messageIsStreaming}
          messageIndex={messageIndex}
          selectedConversationLength={conversations?.length ?? 0}
        />
      );
    } else {
      return <UserChatMessage message={message} displayAvatar={true} />;
    }
  };

  return (
    <Flex justify={message.role === 'assistant' ? 'flex-start' : 'flex-end'}>
      {renderChat()}
    </Flex>
  );
});
ChatMessage.displayName = 'ChatMessage';
