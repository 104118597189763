import React, { FC, useContext } from 'react';
import HomeContext from '@pages/context/HomeContext';
import { CodeBlock } from '../hMarkdown/CodeBlock';
import { MemoizedReactMarkdown } from '../hMarkdown/MemoizedReactMarkdown';
// import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
// import remarkMath from 'remark-math';
import { Flex } from '@duxton-web/ui';
import { MessageActions } from './MessageActions';
import { copyToClipboard } from './utils';

export const TextChatMessage = ({
  message,
  messageIsStreaming,
  messageIndex,
  selectedConversationLength,
}) => {
  const {
    state: { conversations, loading },
    dispatch,
  } = useContext(HomeContext);

  const handleCopy = () => {
    if (typeof message.content === 'string') {
      copyToClipboard(message.content, () => {});
    }
  };

  const handleOperate = async (operation) => {
    const latestConversation = {
      content: 'Please double check this configuration and submit your configuration.',
      role: 'user',
      type: 'inputting',
      action: {
        ...message.action,
        operation: operation,
      },
    };

    conversations.push(latestConversation);

    dispatch({
      field: 'conversations',
      value: conversations,
    });
  };
  return (
    <Flex
      vertical
      flex="1"
      style={{
        borderRadius: '20px',
        // backgroundColor: '#F3F6FB',
        maxWidth: '90%',
        lineHeight: '20px',
      }}
    >
      <div
        style={{
          borderRadius: '20px',
          backgroundColor: '#E5E9F0',
          padding: '8px 16px',
          overflow: 'auto',
        }}
      >
        <MemoizedReactMarkdown
          remarkPlugins={[remarkGfm]}
          // rehypePlugins={[rehypeMathjax]}
          components={{
            code({ inline, className, children, ...props }) {
              console.log(inline, className, children, props, 1);
              const match = /language-(\w+)/.exec(className || '');
              return !inline ? (
                <CodeBlock
                  key={Math.random()}
                  language={(match && match[1]) || ''}
                  value={String(children).replace(/\n$/, '')}
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            table({ children }) {
              return (
                <table
                  style={{
                    borderCollapse: 'collapse',
                    border: '1px solid black',
                    padding: '0.75rem',
                  }}
                >
                  {children}
                </table>
              );
            },
            th({ children }) {
              return (
                <th
                  style={{
                    wordBreak: 'break-word',
                    border: '1px solid black',
                    backgroundColor: '#6b7280',
                    padding: '0.75rem',
                    color: 'white',
                  }}
                >
                  {children}
                </th>
              );
            },
            td({ children }) {
              return (
                <td
                  style={{
                    wordBreak: 'break-word',
                    border: '1px solid black',
                    padding: '0.75rem',
                  }}
                >
                  {children}
                </td>
              );
            },
            img({ src, alt }) {
              return (
                <img
                  src={src}
                  alt={alt}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              );
            },
          }}
        >
         
          {`${message.content}`}
          {/* {`"{\\n  \\"employees\\": [\\n    {\\n      \\"firstName\\": \\"John\\",\\n      \\"lastName\\": \\"Doe\\"\\n    },\\n    {\\n      \\"firstName\\": \\"Anna\\",\\n      \\"lastName\\": \\"Smith\\"\\n    },\\n    {\\n      \\"firstName\\": \\"Peter\\",\\n      \\"lastName\\": \\"Jones\\"\\n    }\\n  ]\\n}"`} */}
          {/* {```json{\n  "employees": [\n    {\n      "firstName": "John",\n      "lastName": "Doe"\n    },\n    {\n      "firstName": "Anna",\n      "lastName": "Smith"\n    },\n    {\n      "firstName": "Peter",\n      "lastName": "Jones"\n    }\n  ]\n}'`} */}
          {/* { "```json\n{\n    \"Type\": \"AIJOB\", \n    \"Operation\": \"EXECUTE\",\n    \"Target\": \"run job full\",\n    \"DefaultConfig\": {\"cronExpression\":\"0/10 * * * *\",\"enableDebugLogs\":false,\"clusterIDToDataTypes\":{\"elastic-beiming\":[\"prepare-task\"]},\"indexDataMode\":\"default\",\"validateDataCount\":false}\n}\n```"} */}
        </MemoizedReactMarkdown>
      </div>
      <MessageActions
        isLastMessage={messageIndex === selectedConversationLength - 1}
        isRegenerateDisabled={messageIsStreaming}
        actions={message?.action}
        onCopy={handleCopy}
        onOperate={handleOperate}
      />
    </Flex>
  );
};
