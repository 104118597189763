import React from 'react';
import { Avatar, Flex } from '@duxton-web/ui';
import { AiOutlined } from '@duxton-web/icons';
import styles from './ChatLoader.less';

export const ChatLoader = () => {
  return (
    <Flex
      align="flex-start"
      gap="8px"
      style={{ marginBottom: '16px', maxWidth: 'calc(100% - 40px)' }}
    >
      <Avatar
        style={{
          background:
            'linear-gradient(133.53deg, #005339 -6.94%, #00B14F 72.93%)',
        }}
        size={32}
        icon={<AiOutlined style={{ fontSize: '16px' }} />}
      />

      <div
        style={{
          borderRadius: '24px',
          backgroundColor: '#F3F6FB',
          padding: '8px 16px',
          lineHeight: '20px',
          width: 'fit-content',
        }}
      >
        <Flex
          align="center"
          justify="center"
          style={{
            padding: '8px 16px',
          }}
        >
          <div className={styles.dotFlashing}></div>
        </Flex>
      </div>
    </Flex>
  );
};
