import {
  useContext,
  useState,
} from 'react';
import { message } from '@duxton-web/ui'
import HomeContext from '@pages/context/HomeContext';
import { useQueryClient } from '@tanstack/react-query';

const useChatInput = (props) => {
  const {
    state: {
      selectedConversation,
      latestChatId,
      selectedChatId,
      messageIsStreaming,
      loading,
    },
    dispatch: homeDispatch,
    handleStartNewConversation,
    handleStartLatestConversation,
    handleClearHistory,
    setChatOptions,
  } = useContext(HomeContext);

  const [content, setContent] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const queryClient = useQueryClient();
  const handleChange = (e) => {
    const value = e.target.value;
    const maxLength = selectedConversation?.model.maxLength;

    if (maxLength && value.length > maxLength) {
      alert(
        `Message limit is ${maxLength} characters. You have entered ${value.length} characters.`
      );
      return;
    }

    setContent(value);
  };

  const handleSend = () => {
    if (messageIsStreaming) {
      return;
    }

    if (!content) {
      // alert('Please enter a message');
      message.warning({ content: 'Please enter a message' })
      return;
    }

    props.onSend({
      role: 'user',
      content: content || '',
      properties: {
        aspectRatio:
          selectedConversation?.chatOptions?.ImageRatio?.data.resolution,
        documentAnalysis: undefined,
      },
    });
    setContent('');

    if (window.innerWidth < 640 && props.textareaRef.current) {
      props.textareaRef.current.blur();
    }
  };

  const handleStopConversation = () => {
    queryClient.cancelMutations();
    props.stopConversationRef.current = true;
    setTimeout(() => {
      props.stopConversationRef.current = false;
    }, 1000);
  };

  const isMobile = () => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return mobileRegex.test(userAgent);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isTyping && !isMobile() && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === '/' && e.metaKey) {
      e.preventDefault();
    }
  };

  return {
    latestChatId,
    selectedChatId,
    selectedConversation,
    messageIsStreaming,
    loading,
    content,
    isTyping,
    handleClearHistory,
    handleChange,
    handleSend,
    handleStopConversation,
    handleStartNewConversation,
    handleStartLatestConversation,
    handleKeyDown,
    setContent,
    setIsTyping,
    setChatOptions,
  };
};

export default useChatInput;
