import { Button, Flex, Space } from '@duxton-web/ui';
import { ChooseUniversal } from '@duxton-web/icons';
import React from 'react';

const ActionButton = ({
  onClick,
  disabled,
  children,
}) => (
  <Button
    size='small'
    disabled={disabled}
    onClick={() => !disabled && onClick && onClick()}
  >
    {children}
  </Button>
);

export const MessageActions = ({
  isLastMessage,
  actions,
  onOperate,
}) => {
  return (
    <Flex
      align="center"
      style={{
        padding: '6px 0px',
        fontSize: '16px',
      }}
    >
      {onOperate && <Space>
        {actions?.operation &&  <ActionButton onClick={() => onOperate(actions?.operation)} disabled={!isLastMessage} key={actions?.operation}>
          <ChooseUniversal /> {actions?.operation}
        </ActionButton>}
      </Space>}
    </Flex>
  );
};
