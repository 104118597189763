import React, { useContext } from 'react';
import { Avatar, Flex, Typography } from '@duxton-web/ui';
import { MessageEditor } from './MessageEditor';
import HomeContext from '@pages/context/HomeContext';
import { getCurrentUser } from 'grab-login/lib/helper';

const { Text } = Typography;
function UserChatMessage({ message, displayAvatar }) {
  // const userEmail = getUserEmail();

  const {
    handleSend,
    hanldeDeleteTemporaryMessage,
  } = useContext(HomeContext);
  const currentUser = getCurrentUser();

  const hanldeCancel = () => {
    hanldeDeleteTemporaryMessage();
  };
  const handleSave = (message) => {
    handleSend(message);
  };
  return (
    <Flex
      align="flex-start"
      gap="8px"
      style={{ marginBottom: '16px', maxWidth: 'calc(100% - 40px)' }}
    >
      <Flex
        vertical
        style={{
          flex: 1,
          borderRadius: '24px',
          overflow: 'hidden',
          border: message.properties?.documentAnalysis?.length
            ? '1px solid #D7DCE4'
            : 'none',
        }}
      >
        {message.action?.operation && (
          <MessageEditor
            message={message}
            onSave={handleSave}
            onCancel={hanldeCancel}
          />
        )}
        {message.content && (
          <div
            style={{
              flex: 1,
              borderRadius: '24px',
              backgroundColor: '#F3F6FB',
              padding: '8px 16px',
              lineHeight: '20px',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {message.content.toString()}
          </div>
        )}
      </Flex>

      {displayAvatar && (
        <Avatar
          style={{
            fontSize: '16px',
          }}
          size={32}
        >
          <Text>{currentUser?.name?.split('.')?.[0] || '-'}</Text>
        </Avatar>
      )}
    </Flex>
  );
}

export default UserChatMessage;
