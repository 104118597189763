import React, { MutableRefObject, useState } from 'react';

import useChatInput from '@pages/hooks/useChatInput';

// import { ChatModeEnum, ChatOptionType, Message } from '@src/types/chat';
// import { OpenAIModelID } from '@src/types/openai';
import ChatTextArea from './ChatTextArea';
// import HelperText from './HelperText';
import SendButton from './SendButton';
import { Card, Flex, Button, Space } from '@duxton-web/ui';
// import { SuggestionMessages } from './SuggestionMessages';
// import { ChatConfig } from './ChatConfig';
// import { sendScribeEvent } from '@src/utils/instrumentation/scribe';
// import { ScribeEvents } from '@src/constants/eventTracking';
// import { ChatConfigOptions } from '@src/constants/chat';
// import PromptList from './PromptList';
import { UserGroupOutlined } from '@duxton-web/icons';

// interface Props {
//   onSend: (message: Message) => void;
//   stopConversationRef: MutableRefObject<boolean>;
//   textareaRef: MutableRefObject<HTMLTextAreaElement | null>;
// }

export const ChatInput = ({
  chatInputVisible,
  onSend,
  onStartNewChatClick,
  stopConversationRef,
  textareaRef,
}) => {
  const {
    selectedConversation,
    messageIsStreaming,
    content,
    latestChatId,
    selectedChatId,
    handleChange,
    handleSend,
    handleKeyDown,
    handleStartNewConversation,
    handleStartLatestConversation,
    handleClearHistory,
  } = useChatInput({ onSend, stopConversationRef, textareaRef });

  const focusOnTextArea = () => {
    textareaRef.current?.focus();
  };

  const handleClearTheHistory = (e) => {
    // handleStartNewConversation()
    handleClearHistory()
  }
  
  const handleLatestHistory = (e) => {
    handleStartLatestConversation()
  }

  return (
    <Flex vertical style={{ boxShadow: '0px -30px 40px 0px white' }}>
      <Card
        style={{ borderRadius: 24, overflow: 'hidden', position: 'relative' }}
        bodyStyle={{ padding: 'unset' }}
      >
        <Flex vertical>
          <ChatTextArea
            textareaRef={textareaRef}
            content={content}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            placeholder={'Ask me anything! Type "/" to input saved prompts.'}
          />
          <Card
            bodyStyle={{ padding: 'unset' }}
            style={{
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
            }}
          >
            <Flex
              gap={12}
              vertical={false}
              justify="end"
              style={{
                padding: 12,
                cursor: 'text',
              }}
              onClick={() => {
                focusOnTextArea();
              }}
            >
              <SendButton
                onSend={handleSend}
                messageIsStreaming={messageIsStreaming}
              />
            </Flex>
          </Card>
          {chatInputVisible && <Card
            bodyStyle={{ padding: 'unset' }}
            style={{
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              padding: '12px',
              borderBottom: selectedConversation?.shareId ? undefined : 'none',
            }}
          >
            <Space size={6}>
              <Button size="small" type="secondary" onClick={handleClearTheHistory}>
                Clear Chat History
              </Button>
              {!!latestChatId && <Button size="small" type="secondary" onClick={handleLatestHistory} disabled={latestChatId === selectedChatId}>
                Latest Chat
              </Button>}
            </Space>
          </Card>}
        </Flex>
        {!chatInputVisible && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(215, 215, 215, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <Space>
              <Button type="primary" onClick={() => onStartNewChatClick(true)}>
                Start New Chat
              </Button>
              {!!latestChatId && <Button onClick={() => onStartNewChatClick(false)}>
                Latest Chat
              </Button>}
            </Space>
          </div>
        )}
      </Card>
      {/* <HelperText /> */}
    </Flex>
  );
};
