import React, { memo, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import styles from './index.less';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Button, message } from '@duxton-web/ui';
import { isString } from 'lodash';

// import 'brace/ext/beautify';

import { CloseCircleOutlined } from '@duxton-web/icons';

// interface CommonEditorProps {
//   mode?: string;
//   theme?: string;
//   fontSize?: number;
//   styleClass?: string;
//   height?: string;
//   placeholder?: string;
//   readonly?: boolean;
//   onChange?: (value: any) => void;
//   defaultValue?: string;
//   value?: string;
//   overlay?: boolean;
//   setShowOverlay?: (value: any) => void;
// }

const DEFAULT_PLACEHOLDER_1 = '// Please inputting a right json';

const CommonEditor = (props, ref) => {
  const {
    mode = 'json',
    theme = 'tomorrow',
    fontSize = 14,
    styleClass = '',
    height = '369px',
    placeholder = DEFAULT_PLACEHOLDER_1,
    readonly = false,
    onChange = () => {},
    defaultValue = '',
    value = '',
    overlay = false,
    setShowOverlay = () => {},
  } = props;

  const [innerVal, setInnerVal] = useState();

  useEffect(() => {
    try {
      if (value) {
        // const formatString = JSON.stringify(value, null, 2);
        const formatString = isString(value) ? JSON.stringify(JSON.parse(value), null, 2) : JSON.stringify(value, null, 2);
        setInnerVal(formatString);
      }
    } catch (e) {
      console.log(e);
    }
  }, [value]);

  const handleChange = (val) => {
    try {
      var parseNext = JSON.parse(val);
      setInnerVal(val);
      if (typeof val === 'string') {
        onChange(parseNext);
      } else {
        onChange(val);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBeautify = () => {
    try {
      const res = JSON.stringify(JSON.parse(innerVal), null, 2);
      setInnerVal(res);
    } catch (e) {
      console.log(e);
      message.error('Beautifier failed');
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.overlayGuideline}
        style={{
          display: overlay ? 'block' : 'none',
        }}
      >
        {DEFAULT_PLACEHOLDER_1?.split('\n').map((item) => (
          <div key={item}>{item.toUpperCase()}</div>
        ))}
        <div
          className={styles.closeOverlayIcon}
          onClick={() => {
            setShowOverlay(false);
          }}
        >
          <CloseCircleOutlined />
        </div>
      </div>
      <Button
        onClick={handleBeautify}
        style={{
          top: ' -70px',
          position: 'absolute',
          right: 0,
          marginBottom: 10,
        }}
      >
        Beautify
      </Button>
      <AceEditor
        mode={mode}
        theme={theme}
        fontSize={fontSize}
        className={`${styles.aceEditor} ${styleClass}`}
        height={height}
        placeholder={placeholder}
        onChange={handleChange}
        value={innerVal}
        defaultValue={defaultValue}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        width="100%"
        wrapEnabled={true}
        readOnly={readonly}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default memo(CommonEditor);
