import React, { useContext } from 'react';
import HomeContext from '@pages/context/HomeContext';
import { ChatLoader } from '../hChatLoading/ChatLoader';
import { ChatMessage } from '../hChatMessage/ChatMessage';

function ChatMessageBody({ messagesEndRef }) {
  const {
    state: { conversations, loading },
  } = useContext(HomeContext);
  return (
    <>
      {conversations?.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          messageIndex={index}
        />
      ))}

      {loading && <ChatLoader />}

      <div
        style={{
          height: '20px',
          backgroundColor: '#fff',
        }}
        ref={messagesEndRef}
      />
    </>
  );
}

export default ChatMessageBody;
