import React, { useState, useMemo, useEffect } from 'react';
import { Button, InputNumber, Card } from '@duxton-web/ui';
import { ChooseUniversal } from '@duxton-web/icons';
import CommonEditor from '@component/CommonEditor';

export const MessageEditor = ({
  message,
  onSave,
  onCancel,
}) => {


  const messageContent = useMemo(() => {
    return message.content
  }, [message]);

  const messageAction = useMemo(() => {
    return message.action
  }, [message]);

  const isEditing = useMemo(() => {
    return message.type === 'inputting'
  }, [message])

  const [innerInputVal, setInnerInputVal] = useState('')


  const CommonEditorWrap = (props) => {
    return <Card type="inner" title="Update Parameter" style={{width: '800px'}}><CommonEditor {...props}></CommonEditor></Card>
  }


  const COMPONET_MAP = useMemo(() => ({
    // 'update': {
    //   component: CommonEditorWrap,
    //   props:  {
    //     height: '300px',
    //     // placeholder: innerInputVal,
    //     defaultValue: messageAction.config,
    //     readonly: !isEditing,
    //     onChange: (val) => {
    //       const valString = JSON.stringify(val, null, 2)
    //       setInnerInputVal(valString)
    //     },
    //     value: innerInputVal,
    //   }
    // },
    'EXECUTE': {
      component: CommonEditorWrap,
      props:  {
        height: '300px',
        defaultValue: messageAction.config,
        readonly: !isEditing,
        onChange: (val) => {
          const valString = JSON.stringify(val, null, 2)
          setInnerInputVal(valString)
        },
        value: innerInputVal,
      }
    },
    'TERMINATE': {
      component: InputNumber,
      props: {
        style: { width: '100%' },
        value: innerInputVal,
        disabled: !isEditing,
       onChange: (val) => {
          setInnerInputVal(val.toString())
        },
      }
    },
  }), [messageAction, innerInputVal, isEditing])

  useEffect(() => {
    setInnerInputVal(message?.action?.config || '')
  }, [message])

  const handleSave = () => {
    onSave({
      content: '',
      role: 'user',
      type: 'submit',
      action: {
        ...message.action,
        config: innerInputVal
      }
    })
  }

  const ComponentToRender = useMemo(() => {
    return  COMPONET_MAP[message?.action?.operation]?.component || null;
  },[COMPONET_MAP, message])

  const componentProps = useMemo(() => {
    return COMPONET_MAP[message?.action?.operation]?.props || {}
  },[COMPONET_MAP, message])

  return (
    <div style={{ display: 'flex', width: '100%',  padding: '10px', flexDirection: 'column' }}>
      {ComponentToRender ?
        <ComponentToRender
          {...componentProps}
        /> : null}
      {isEditing && <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'right',
          gap: '1rem',
        }}
      >
        <Button
          onClick={onCancel}
          size='small'
        >
          <ChooseUniversal /> Cancel
        </Button>
        <Button
          type="primary"
          size='small'
          onClick={handleSave}
          disabled={messageContent.trim().length <= 0}
          
        >
          <ChooseUniversal /> Submit & Execute
        </Button>
      </div>}
    </div>
  );
};
