import React from 'react';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { throttle } from 'loadsh';
import { ChatInput } from './hChatInput';
import ChatMessageContainer from './hChatMessageContainer/ChatMessageContainer';

import HomeContext from '@pages/context/HomeContext';
import { Flex, Space } from '@duxton-web/ui';

export const Chat = memo(({ stopConversationRef }) => {
  const {
    // state: { selectedConversation, modelError },
    handleSend,
    handleStartNewConversation,
    handleStartLatestConversation,
  } = useContext(HomeContext);

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [chatInputVisible, setChatInputVisible] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const bottomTolerance = 30;
      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        setAutoScrollEnabled(false);
      } else {
        setAutoScrollEnabled(true);
      }
    }
  };

  const scrollDown = () => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView(true);
    }
  };
  const throttledScrollDown = throttle(scrollDown, 250);

  useEffect(() => {
    throttledScrollDown();
  }, [throttledScrollDown]);

  const handleStartChat = (isNew) => {
    setChatInputVisible(true);
    if (isNew) {
      handleStartNewConversation();
    } else {
      handleStartLatestConversation();
    }
    
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAutoScrollEnabled(entry.isIntersecting);
        if (entry.isIntersecting) {
          textareaRef.current?.focus();
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    );
    const messagesEndElement = messagesEndRef.current;
    if (messagesEndElement) {
      observer.observe(messagesEndElement);
    }
    return () => {
      if (messagesEndElement) {
        observer.unobserve(messagesEndElement);
      }
    };
  }, [messagesEndRef]);

  // useEffect(() => {
  //   textareaRef.current?.focus();
  // }, [selectedConversation?.id, selectedConversation?.mode]);

  return (
    <Flex
      flex={1}
      vertical
      justify="space-between"
      style={{
        maxWidth: '100%',
        // backgroundColor: 'red',
        height: 'calc(100vh - 104px)',
      }}
    >
      <Space
        direction="vertical"
        style={{ overflow: 'auto', scrollbarWidth: 'thin' }}
      >
        <ChatMessageContainer
          chatInputVisible={chatInputVisible}
          onChangeInputVisible={setChatInputVisible}
          chatContainerRef={chatContainerRef}
          messagesEndRef={messagesEndRef}
          handleScroll={handleScroll}
        />
      </Space>

      <ChatInput
        chatInputVisible={chatInputVisible}
        stopConversationRef={stopConversationRef}
        textareaRef={textareaRef}
        onStartNewChatClick={handleStartChat}
        onSend={(message) => {
          handleSend(message);
        }}
      />
    </Flex>
  );
});
Chat.displayName = 'Chat';
