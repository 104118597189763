import React from 'react';
import { Input } from '@duxton-web/ui';
import style from './style.less';

function ChatTextArea({
  textareaRef,
  content,
  handleChange,
  handleKeyDown,
  placeholder,
}) {
  return (
    <Input.TextArea
      className={style.textArea}
      style={{ border: 'none' }}
      ref={textareaRef}
      placeholder={placeholder || 'Ask me anything!'}
      value={content}
      rows={1}
      autoSize={{ minRows: 1, maxRows: 5 }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
}

export default ChatTextArea;
