export const baseUrl = () => {
  const endpoint = 'https://food-puxian.stg-myteksi.com/foodpuxian';
  // const endpoint = window.Grab.config.concedo.tokenApiBaseUrl;
  return endpoint;
};


export const baseUrl1 = () => {
  // const endpoint = 'https://api-restricted.stg-myteksi.com/foodpuxian/';
  const endpoint = window.Grab.config.concedo.tokenApiBaseUrl;
  return endpoint;
};
