import FetchPlugin from './axios';
/* eslint camelcase: "off" */
import { baseUrl } from './common'
export const getBotHistorys = ({ chat_id, user_input, action }) => {
  return FetchPlugin({
    method: 'post',
    url: `${baseUrl()}/api/ai-bot/search-platform`,
    params: {
      chat_id,
      user_input,
      action
    },
  }).then((res) => {
    return res
  });
};
