import { message, notification } from '@duxton-web/ui';
import axios from 'axios';
import { getToken } from '@pages/sessions/concedoLogin/helper';
// import createLog from 'utils/log'
import { get } from 'lodash'
// const log = createLog('geo-tools:api:index')
/* eslint camelcase: "off" */

const CustomApi = axios.create({
  timeout: 100 * 1000, // 60 s
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
  validateStatus: function (status) {
    return status < 300;
  },
});

CustomApi.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = 'Bearer ' + getToken();

    switch (config.method) {
      case 'get':
        config.params = {
          ...config.params,
        };
        return config;
      case 'put':
      case 'post':
      case 'patch':
        config.data = {
          ...config.data,
        };

        return config;
      default:
        return config;
    }
  },
  (err) => {
    // log.error('[API] Request error', { errorObject: err })
    notification.error({
      message: 'Could not send the request right now. Please try again later.',
    });
    return Promise.reject(err);
  }
);
CustomApi.interceptors.response.use(
  (res) => {
    const { data, config } = res;
    const realData = data?.body;
    if (realData) {
      if (!realData?.code) {
        // business error
        if (realData.business_error_count) {
          return {
            code: 1000,
            msg:
              realData.business_error_list
                ?.map((item) => item.msg)
                ?.join(', ') || 'Action Error',
            data: realData.business_error_list,
          };
        }

        if (config.responsePath) {
          return get(realData, config.responsePath);
        }
        // todo other endpoint need be fixed here
        return realData?.body;
      } else {
        return {
          code: 1001,
          msg: realData?.msg,
        };
      }
    }

    if (config.responsePath) {
      return get(realData, config.responsePath);
    }

    return data || {};
    // if (!data.err_info || data?.err_info?.code !== 1000001) {
    //   if (config.hideTips) {
    //     return data;
    //   }

    //   if (config.shot) {
    //     notification.error({
    //       message: data?.err_info?.message || 'Request Failed!',
    //     });
    //     return data;
    //   }
    //   return Promise.reject(
    //     new Error(data?.err_info?.message || 'Sevice Error')
    //   );
    // } else {
    //   config.showSuccessTips &&
    //     notification.success({
    //       message: data?.err_info?.message || 'Save Successfully ',
    //     });
    //   return data || {};
    // }
  },
  (err) => {
    if (!err.config) {
      console.warn(
        'config not found in axios error, maybe request is cancelled.'
      );
      return Promise.reject(err);
    }

    const { response, config } = err;
    if (!err.response) {
      return Promise.reject(err);
    }

    const { status } = response;
    if (status === 401) {
      // unauthorized
      let msg = 'Your Authorization Has Expired';
      window.location.href = '/login';
      message.error(msg, 3);
      return;
    }

    if (config.shot) {
      return {};
    }
    if (config && config.hideTips === true) return Promise.reject(err);
    return Promise.reject({
      code: status,
      msg:
        response.data?.message ||
        response.data?.reason ||
        `Request failed with status code ${status}`,
    });
  }
);

const FetchPlugin = async ({ url, method = 'get', params, config }) => {
  try {
    const serviceUrl = url;
    let result;
    switch (method) {
      case 'get':
        result = await CustomApi.get(serviceUrl, config);
        break;
      case 'post':
        result = await CustomApi.post(serviceUrl, params, config);
        break;
      case 'patch':
        result = await CustomApi.patch(serviceUrl, params, config);
        break;
      case 'delete':
        result = await CustomApi.delete(serviceUrl, config);
        break;
    }

    return result;
  } catch (axiosError) {
    notification.error({
      message: axiosError?.msg || 'Request Failed!',
    });
    return Promise.reject(axiosError);
  }
};

export default FetchPlugin;
