import { AiOutlined } from '@duxton-web/icons';
import { Avatar, Flex } from '@duxton-web/ui';
import React from 'react';
import { TextChatMessage } from './TextChatMessage';


function AssistantChatMessage({
  message,
  messageIsStreaming,
  messageIndex,
  selectedConversationLength,
}) {
  return (
    <Flex
      align="flex-start"
      gap="8px"
      style={{ marginBottom: '16px', maxWidth: 'calc(100% - 40px)' }}
    >
      <Avatar
        style={{
          background:
            'linear-gradient(133.53deg, #005339 -6.94%, #00B14F 72.93%)',
        }}
        size={32}
        icon={<AiOutlined style={{ fontSize: '16px' }} />}
      />
       <TextChatMessage
          message={message}
          messageIsStreaming={messageIsStreaming}
          messageIndex={messageIndex}
          selectedConversationLength={selectedConversationLength}
        />
    </Flex>
  );
}

export default AssistantChatMessage;
