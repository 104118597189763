import React from 'react';
import { Flex, Typography } from '@duxton-web/ui';
import styled from '@emotion/styled';

const TitleWrapper = styled.p`
  color: #4caf50;
  font-size: 20px;
`;

function ChatInitialSetup({ style = {} }) {
  const gradientColorStyle = {
    background: 'linear-gradient(90deg, #015A28 27.34%, #01A74B 57.8%)',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 800,
  };

  const renderText = () => {
    return (
      <div>
        <h1>
          <span style={gradientColorStyle}>Search Platform</span> Web Portal
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;turn tasks into{' '}
          <span style={gradientColorStyle}>More Intelligent!</span>
        </h1>
      </div>
    );
  };

  const renderText2 = () => {
    return (
      <>
        <p>
          The search platform web portal is used for the management of
          search-platform resources and contains both search(online search) and
          puxian(offline data sync)
        </p>
        <p>
          <TitleWrapper>1. What is the scheduled job?</TitleWrapper>A scheduled
          job is a scheduled task run in puxian instance, usually, designed to
          do some task scheduled, for example, data full sync,
          auto-check(validate), and some monitor
        </p>
        <p>
          <TitleWrapper>2. What is the stream?</TitleWrapper>
          Stream is usually used for real-time or asynchronous data processing,
          for example, the Mysql binlog stream
        </p>
        <p>
          <TitleWrapper>3. What is the script?</TitleWrapper>
          The script is designed to execute scripts, for example, check data of
          Redis(getIntent stored in Redis)
        </p>
        <p>
          <TitleWrapper>4. How to request access?</TitleWrapper>
          We are using Concedo for RBAC management, you can apply for permission
          by{' '}
          <a href={window.Grab.config.concedo.url} target="_black">
            Concedo
          </a>
          (App name: search-platform) Different team members should apply for
          different roles; if you need all permissions, should apply for the
          super role
        </p>
      </>
    );
  };

  return (
    <Flex
      vertical
      align="center"
      gap="24px"
      style={{
        minHeight: '350px',
        ...style,
      }}
    >
      <Typography.Text
        style={{
          fontSize: '40px',
          lineHeight: '44px',
          color: '#3D3D3D',
          fontFamily: 'Grab Community Solid',
        }}
      >
        {renderText()}
      </Typography.Text>

      <Typography.Text
        style={{
          textAlign: 'left',
          fontSize: '18px',
          lineHeight: '20px',
          color: '#3D3D3D',
          fontFamily: 'Grab Community Solid',
        }}
      >
        {renderText2()}
      </Typography.Text>
    </Flex>
  );
}

export default ChatInitialSetup;
