import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useCreateReducer } from '@pages/hooks/useCreateReducer';
import { BackgroudWithWhiteContainer, PageOverview } from '@component/Style';
import { useChatMutation } from '@pages/home/apiHooks';
import { Chat } from './home/index';
import HomeContext from '@pages/context/HomeContext';
import {
  initialState,
} from '@pages/context/HomeContext/home.state';
import { Modal } from '@duxton-web/ui';
// import { getUserEmail } from '@data-platform/concedo-auth';
import { useParams } from 'react-router';

const Home = () => {
  const { id } = useParams();
  const [modal, modalContextHolder] = Modal.useModal();
  const { mutateAsync: getBotHistorys } = useChatMutation();
  const contextValue = useCreateReducer({
    initialState,
  });

  const {
    state: { latestChatId, selectedChatId, conversations, selectedConversation },
    dispatch,
  } = contextValue;

  // const userEmail = getUserEmail();
  const stopConversationRef = useRef(false);

  useEffect(() => {
    const localChatId = localStorage.getItem('latestChatId');
    if (localChatId) dispatch({ field: 'latestChatId', value: localChatId });
  }, [dispatch]);

  const handleStartNewConversation = () => {
    if (selectedChatId) {
      dispatch({
        field: 'latestChatId',
        value: selectedChatId,
      });
    }
    dispatch({
      field: 'selectedChatId',
      value: undefined,
    });

    dispatch({
      field: 'conversations',
      value: [],
    });
    // dispatch({
    //   field: 'conversations',
    //   value: [
    //     {
    //       content: `I'm glad you're about to start your wonderful journey. How can I help you? `,
    //       role: 'assistant',
    //     },
    //   ],
    // });
  };

  const handleStartLatestConversation = () => {
    dispatch({
      field: 'selectedChatId',
      value: latestChatId,
    });
    handleGetHistoryWithChatId(latestChatId)
  };


  const handleClearHistory = () => {
    dispatch({
      field: 'conversations',
      value: [],
    });
    dispatch({
      field: 'selectedChatId',
      value: undefined,
    });
    const latestChatId = localStorage.getItem('latestChatId');
    if (latestChatId) {
      dispatch({
        field: 'latestChatId',
        value: latestChatId,
      });
    }
  };

  const hanldeDeleteTemporaryMessage = async () => {
    const confirmed = await modal.confirm({
      title: 'Do you want to give up this action?',
      content: 'If you click OK, this message will be lost.',
    });
    if (!confirmed) {
      return;
    }
    const latestMessage = conversations?.[conversations?.length - 1];
    let newChatHistory = conversations.slice(0);
    if (latestMessage.type === 'inputting' && latestMessage.role === 'user') {
      newChatHistory.pop();
      dispatch({
        field: 'conversations',
        value: newChatHistory,
      });
    }
  };

  const handleSend = useCallback(
    async ({ content = '', type = '', action = undefined } = {}) => {
      let userInputMessage = [{
        content,
        role: 'user',
      }]
      if (!selectedChatId) {
        dispatch({
          field: 'conversations',
          value: userInputMessage,
        });
  
        dispatch({
          field: 'loading',
          value: true,
        });
        
        const response = await getBotHistorys({user_input: content});
        dispatch({
          field: 'conversations',
          value: response?.chat_history,
        });

        dispatch({
          field: 'selectedChatId',
          value: response?.chat_id,
        });
        dispatch({
          field: 'loading',
          value: false,
        });
        
        localStorage.setItem('latestChatId', response?.chat_id);
        // if (response?.chat_id !== localChatId) {
        //   localStorage.setItem('chatId', response?.chat_id);
        // }
        return;
      }

      // if type === 'submit' then updat the latest message and send to the server
      // if latest message is user message
      const latestMessage = conversations?.[conversations?.length - 1];
      let newChatHistory = conversations.slice(0);

      if (
        latestMessage &&
        latestMessage.type === 'inputting' &&
        latestMessage.role === 'user'
      ) {
        if (type === 'submit') {
          newChatHistory[conversations?.length - 1] = {
            content,
            action,
            role: 'user',
          };
        } else {
          const confirmed = await modal.confirm({
            title: 'Do you want to give up the latest action?',
            content: 'If you click OK, the latest action will be given up.',
          });
          if (!confirmed) {
            return;
          }
          newChatHistory[conversations?.length - 1] = {
            content,
            action,
            role: 'user',
          };
        }
      } else if (content || action) {
        newChatHistory = newChatHistory?.concat({
          content,
          action,
          role: 'user',
        });
      }

      dispatch({
        field: 'conversations',
        value: newChatHistory,
      });

      dispatch({
        field: 'loading',
        value: true,
      });

      setTimeout(async () => {
        const response = await getBotHistorys({
          chat_id: selectedChatId,
          user_input: content,
          action: action,
        });
    
        dispatch({
          field: 'conversations',
          value: response?.chat_history,
        });
    
        dispatch({
          field: 'loading',
          value: false,
        });
      }, 1000);
    },
    [conversations, selectedChatId]
  );


  const handleGetHistoryWithChatId = async (latestChatId) => {
    const response = await getBotHistorys({
      chat_id: latestChatId,
    });

    dispatch({
      field: 'conversations',
      value: response?.chat_history,
    });
  }

  return (
    <HomeContext.Provider
      value={{
        ...contextValue,
        handleStartNewConversation,
        handleStartLatestConversation,
        hanldeDeleteTemporaryMessage,
        handleClearHistory,
        handleSend,
      }}
    >
      {modalContextHolder}
      <PageOverview>
        <BackgroudWithWhiteContainer style={{ padding: '40px' }}>
          <Chat
            stopConversationRef={stopConversationRef}
            userEmail={'userEmail'}
          />
        </BackgroudWithWhiteContainer>
      </PageOverview>
    </HomeContext.Provider>
  );
};
export default Home;
