import React, { useMemo } from 'react';
import { SendUniversal } from '@duxton-web/icons';
import { Button } from '@duxton-web/ui';

function SendButton({ onSend, messageIsStreaming }) {
  const options = useMemo(() => {
    return {
      handler: onSend,
      icon: <SendUniversal />,
      style: {},
    };
  }, [messageIsStreaming, onSend]);

  return (
    <Button
      size="small"
      type="primary"
      onClick={options.handler}
      shape="circle"
      icon={options.icon}
      style={options.style}
    />
  );
}

export default SendButton;
