import { useMemo, useReducer } from 'react';

// import { Conversation, Message } from 'src/types/chat';
// import { ErrorMessage } from 'src/types/error';
// import { FolderInterface } from 'src/types/folder';
// import { OpenAIModel, OpenAIModelID } from 'src/types/openai';
// import { Prompt } from 'src/types/prompt';

// export interface HomeInitialState {
//   loading: boolean;
//   messageIsStreaming: boolean;
//   modelError: ErrorMessage | null;
//   models: OpenAIModel[];
//   folders: FolderInterface[];
//   conversations: Conversation[];
//   selectedConversation: Conversation | undefined;
//   currentMessage: Message | undefined;
//   prompts: Prompt[];
//   temperature: number;
//   currentFolder: FolderInterface | undefined;
//   messageError: boolean;
//   searchTerm: string;
//   defaultModelId: OpenAIModelID | undefined;
//   serverSideApiKeyIsSet: boolean;
//   serverSidePluginKeysSet: boolean;
//   selectedPrompt: Prompt | undefined;
// }



export const initialState = {
  chatId: undefined,
  loading: false,
  selectedChatId: undefined,
  latestChatId: undefined,
  messageIsStreaming: false,
  // modelError: null,
  // models: [],
  // folders: [],
  conversations: [],
  selectedConversation: undefined,
  latestConversation: undefined,
  currentMessage: undefined,
  // prompts: [],
  // temperature: 1,
  // currentFolder: undefined,
  messageError: false,
  searchTerm: '',
  // defaultModelId: undefined,
  // serverSideApiKeyIsSet: false,
  // serverSidePluginKeysSet: false,
  // selectedPrompt: undefined,
};
