import React, { useContext } from 'react';
import ChatInitialSetup from './ChatInitialSetup';
import ChatMessageBody from './ChatMessageBody';
import { Space, Button, Typography } from '@duxton-web/ui'
import { ArrowLeftUniversal } from '@duxton-web/icons'
import HomeContext from '@pages/context/HomeContext';

// interface Props {
//   chatContainerRef: MutableRefObject<HTMLDivElement | null>;
//   messagesEndRef: MutableRefObject<HTMLDivElement | null>;
//   handleScroll: () => void;
//   // onRegenerate: () => void;
// }
const { Body } = Typography

const ChatMessageContainer = ({
  chatInputVisible,
  onChangeInputVisible,
  chatContainerRef,
  messagesEndRef,
  handleScroll,
}) => {
  const {
    state: { conversations },
    handleClearHistory
  } = useContext(HomeContext);
  const [isConversationPage, setIsConversationPage] = React.useState(false);

  const goBack = () => {
    setIsConversationPage(false)
    onChangeInputVisible(false)
    handleClearHistory()
  }

  return (
    <div
      style={{
        maxHeight: '100%',
        overflowX: 'hidden',
        maxWidth: '100%',
      }}
      ref={chatContainerRef}
      onScroll={handleScroll}
    >
      {(!chatInputVisible && conversations?.length === 0) ? (
        <ChatInitialSetup />
      ) : (
          <>
            <Space align="center" style={{ marginBottom: '24px' }}>
              <Button
                type="text"
                onClick={goBack}
                icon={<ArrowLeftUniversal style={{ fontSize: '16px' }} />}
              ></Button>
              <Body strong>Back</Body>
            </Space>
            <ChatMessageBody
              messagesEndRef={messagesEndRef}
            />
        </>
      )}
    </div>
  );
};

export default ChatMessageContainer;
